// extracted by mini-css-extract-plugin
export var otherCategories = "product-page-module--other-categories--EbYow";
export var otherCategories__slide = "product-page-module--other-categories__slide--UL-ua";
export var otherCategories__slider = "product-page-module--other-categories__slider--SVJyt";
export var otherProducts = "product-page-module--other-products--aSwuT";
export var otherProducts__slide = "product-page-module--other-products__slide--ovM1g";
export var otherProducts__slider = "product-page-module--other-products__slider--V--v5";
export var productDescription = "product-page-module--product-description--BJDHK";
export var productDescription__btnGroup = "product-page-module--product-description__btn-group--BVXt4";
export var productDescription__container = "product-page-module--product-description__container--MMwcm";
export var productDescription__content = "product-page-module--product-description__content--Ae8dE";
export var productDescription__slider = "product-page-module--product-description__slider--i2Vh3";