import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { MainSlider, MainSlide, NavSlider, NavSlide, Image } from './ProductSlider.styled';
import Carousel, { Modal, ModalGateway } from 'react-images';

const ProductSlider = ({ title, images }) => {
  const productSlider = React.useRef();
  const productSliderNav = React.useRef();
  const [sliderConf, setSliderConf] = React.useState();
  const [sliderNavConf, setSliderNavConf] = React.useState();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setSliderConf({
      asNavFor: productSliderNav.current,
      arrows: false
    });

    setSliderNavConf({
      asNavFor: productSlider.current,
      arrows: false,
      slidesToShow: 5,
      swipeToSlide: true,
      focusOnSelect: true,
      centerMode: true,
      centerPadding: 0
    });
  }, []);

  const openModal = event => {
    document.documentElement.style.overflow = 'hidden';
    setOpen(true);
  };

  const closeModal = () => {
    document.documentElement.removeAttribute('style');
    setOpen(false);
  };

  return (
    <div>
      <div onClick={openModal} onKeyDown={openModal}>
        <MainSlider ref={productSlider} {...sliderConf}>
          {images.map((image) => (
            <MainSlide key={image.childImageSharp.id}>
              <Image image={getImage(image)} alt={title} />
            </MainSlide>
          ))}
        </MainSlider>
      </div>
      {images.length > 1 && <NavSlider ref={productSliderNav} {...sliderNavConf} className={images.length < 5 && 'hide-cloned'}>
        {images.map((image) => (
          <NavSlide key={image.childImageSharp.id}>
            <Image image={getImage(image)} alt={title} />
          </NavSlide>
        ))}
      </NavSlider>}
      <ModalGateway>
        {open ? (
          <Modal onClose={closeModal}>
            <Carousel views={images.map(i => ({ source: getImage(i).images.fallback.src }))} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default ProductSlider;
