import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';

export const MainSlider = styled(Slider)`
  width: 100%;
  margin: 0 0 10px;
  cursor: pointer;
`;

export const MainSlide = styled.div`
  background-color: #F2F2F2;
  text-align: center;
`;

export const NavSlider = styled(Slider)`
  width: 100%;
  overflow: hidden;

  .slick-list {
    margin: 0 -5px;
  }

  .gatsby-image-wrapper {
    border: 1px solid #F2F2F2;
    opacity: 1;
    transition: opacity .3s;
  }

  .slick-current {
    .gatsby-image-wrapper {
      opacity: .75;
    }
  }

  &.hide-cloned {
    .slick-cloned {
      display: none;
    }
  }
`;

export const NavSlide = styled.div`
  display: block !important;
  padding: 0 5px;
`;

export const Image = styled(GatsbyImage)`
  background-color: #F5F5F5;
  transition: background-color .3s;

  img {
    margin: 0;
  }
`;
